.target-menu {
    --target-menu-size: 150px;
    background-color: rgba(255, 255, 255, 0.685);
    height: var(--target-menu-size);
    width: var(--target-menu-size);
    border: 3px solid white;
    transition: all 1s;
    display: grid;
    grid: 1fr 1fr 1fr/1fr;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.target-menu button {
    width: 95%;
    background-color: rgba(235, 235, 235, 0.619);
}

.target-menu button:hover {
    background-color: rgb(235, 235, 235);
}

.target-menu .flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 2rem;
}

.target-menu .flexContainer img {
    margin-right: 1em;
    height: 3em;
}