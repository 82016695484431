.StartingScreen {
    position: fixed;
    display: grid;
    grid: 1fr/ 1fr 4px 1fr;
    top: 5rem;
    width: 60%;
    min-height: 40%;
    height: 80%;
    background-color: rgba(213, 195, 195, 0.847);
    border: 4px solid beige;
    border-radius: 5rem;
    font-size: 1.2rem;
    overflow: scroll;
}

@media (max-width: 1850px) {
    .StartingScreen {
        font-size: 1.1rem;
        width: 60%;
    }
}

@media (max-width: 1450px) {
    .StartingScreen {
        font-size: 1rem;
        width: 80%;
    }
}


@media (max-width: 1000px) {
    .StartingScreen {
        font-size: 0.9rem;
        width: 90%;
    }
}

.StartingScreen .border {
    border-right: 5px solid rgba(0, 0, 0, 0.319);
}